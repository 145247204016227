<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>
            Team Ontwikkel Plan
          </h1>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p class="font-weight-regular">
            Dit is jullie Team Ontwikkel Plan
          </p>

          <p class="font-weight-regular">
            Waarin willen jullie sterker worden? Wat zijn jullie valkuilen? Dit Team Ontwikkel Plan geeft jullie meer inzicht in wat er gebeurt en
            hoe jullie hier verandering in aan willen brengen.
          </p>

          <p class="font-weight-regular">
            Het plan bestaat uit vier verschillende stappen die jullie in de teamsessie hebben doorlopen. De uitkomst daarvan vind je hieronder.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="text-center">
          <v-card min-width="100%" class="d-inline-block background-blue pa-10 cursor-pointer" @click="openCurrentSituation"
                  :disabled="!current.available">
            <div class="d-flex justify-center">

              <v-icon color="accent" size="60">far fa-file-alt</v-icon>
            </div>
            <div class="d-flex justify-center pt-4">
              Huidige Situatie
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-card min-width="100%" class="d-inline-block background-blue pa-10 cursor-pointer" @click="openDesiredSituation"
                  :disabled="!desired.available">
            <div class="d-flex justify-center">

              <v-icon color="accent" size="60">far fa-file-alt</v-icon>
            </div>
            <div class="d-flex justify-center pt-4">
              Gewenste Situatie
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-card min-width="100%" class="d-inline-block background-blue pa-10 cursor-pointer" @click="openBarriersSituation"
                  :disabled="!barriers.available">
            <div class="d-flex justify-center">

              <v-icon color="accent" size="60">far fa-file-alt</v-icon>
            </div>
            <div class="d-flex justify-center pt-4">
              Belemmeringen
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-card min-width="100%" class="d-inline-block background-blue pa-10 cursor-pointer" @click="openFinal" :disabled="!top.available">
            <div class="d-flex justify-center">

              <v-icon color="accent" size="60">far fa-file-alt</v-icon>
            </div>
            <div class="d-flex justify-center pt-4">
              T.O.P.
            </div>
          </v-card>
        </v-col>
      </v-row>

    </v-container>

    <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show" />
  </div>
</template>

<script>

import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "dashboardWiseTOPBase",
  components: {SnackbarText},
  data() {
    return {
      current: {
        available: false,
        downloadUrl: null,
      },
      desired: {
        available: false,
        downloadUrl: null,
      },
      barriers: {
        available: false,
        downloadUrl: null,
      },
      top: {
        available: false,
        downloadUrl: null,
      },
      error: {
        show: false,
        message: ""
      },
    }
  },
  computed: {
    ...mapGetters({
      token: "auth/accessToken",
    }),
  },
  mounted() {
    apiClient.get("top/get", {params: {type: 3}}).then(response => {
      this.current.available = response.data.current;
      if(response.data.current){
        this.current.downloadUrl = process.env.VUE_APP_API_URL + "top/pdf.pdf?type=1&token=" + this.token;
      }

      this.desired.available = response.data.desired;
      if(response.data.desired){
        this.desired.downloadUrl = process.env.VUE_APP_API_URL + "top/pdf.pdf?type=2&token=" + this.token;
      }

      this.barriers.available = response.data.barriers;
      if(response.data.barriers){
        this.barriers.downloadUrl = process.env.VUE_APP_API_URL + "top/pdf.pdf?type=3&token=" + this.token;
      }

      this.top.available = response.data.top;
      if(response.data.top){
        this.top.downloadUrl = process.env.VUE_APP_API_URL + "top/pdf.pdf?type=4&token=" + this.token;
      }
    }).catch(error => {
      this.undefinedError();
    });
  },
  methods: {
    undefinedError() {
      this.error.message = "Er is een onbekende fout opgetreden.";
      this.error.show = true;
    },
    openCurrentSituation() {
      this.downloadPdf(this.current.downloadUrl, "TeamOntwikkelplan-Huidige_situatie");
    },
    openDesiredSituation() {
      this.downloadPdf(this.desired.downloadUrl, "TeamOntwikkelplan-Gewenste_situatie");
    },
    openBarriersSituation() {
      this.downloadPdf(this.barriers.downloadUrl, "TeamOntwikkelplan-Belemmeringen");
    },
    openFinal() {
      this.downloadPdf(this.top.downloadUrl, "TeamOntwikkelplan-TOP");
    },
    downloadPdf(url, name) {
      window.open(url, "_blank");
    },
  }
};
</script>
