<template>
  <v-snackbar :value="value" @input="update" :timeout="timeout" :color="bgColor" style="z-index: 9000; margin-bottom: 100px">
    {{ text }}

    <template v-if="button">
      <v-btn
          :color="textColor"
          text
          @click="update(false)"
      >
        Sluiten
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>


export default {
  name: "snackbarText",
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      type: Boolean
    },
    timeout: {
      type: Number,
      default: 2500,
    },
    button: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "dark"
    },
    textColor: {
      type: String,
      default: "accent"
    }
  },
  methods: {
    update(newValue) { this.$emit('input', newValue); }
  }
};
</script>
